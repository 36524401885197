module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Dceed: Industry leading IT solutions', // Navigation and Site Title
  titleAlt: 'Dceed Bilişim Teknolojileri A.Ş.', // Title for JSONLD
  description: 'We transform your ideas using latest technology and creativity to achieve meaningful and efficient product experiences.',
  headline: 'We transform your ideas into products.', // Headline for schema.org JSONLD
  url: 'https://dceed.com', // Domain of your site. No trailing slash!
  logo: '/images/logo.png', // Used for SEO
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Dceed', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Abdulkadir Efendioglu', // Author for schemaORGJSONLD
  themeColor: '#247dbd',
  backgroundColor: '#fff',

  twitter: '@dceedas', // Twitter Username
  facebook: 'dceedas', // Facebook Site Name
  googleAnalyticsID: 'UA-XXXXXX-X',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
