/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import '@reach/skip-nav/styles.css'

import SkipNavLink from './SkipNavLink'
import { theme } from '../styles'
import i18n from '../../config/i18n'
import "fontsource-montserrat/latin.css"
import "fontsource-montserrat/latin-ext.css" // All weights and styles included.


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}
const LocaleContext = React.createContext()
const Layout = ({ children, pageContext: { locale } }) => {

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <ThemeProvider theme={theme}>
        <>
          <SkipNavLink />
          {children}
        </>
      </ThemeProvider>
    </LocaleContext.Provider>
  )
}

export { LocaleContext, Layout }

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}