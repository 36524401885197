const website = require('./website')

module.exports = {
  'en-us': {
    default: true,
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: 'Dceed Bilişim Teknolojileri Anonim Şirketi',
    defaultTitleAlt: 'Dceed A.Ş.',
    section: {
      hero: {
        title: "Let's help you",
        description: "We specialize in designing, building, deploying, and scaling world-class products. Let's take your idea to the next level.",
        button: "See how we can help.",
        loop: {
          design: "Design",
          build: "Build",
          deploy: "Deploy",
          scale: "Scale",
          migrate: "Migrate",
        }
      },
      footer: {
        contact: "Contact",
        services: "services",
        software: "Software Development",
        custom: "Custom Solutions",
        business: "Business Solutions",
        consulting: "Consulting",
        outsourcing: "Outsourcing",
      },
      services: {
        title: "Services",
        subtitle: "Let's start your transformation journey now.",
        description: "We are a tech partner, assisting you from product design to continuous delivery. Our key services include Software Development, Custom Solutions, Business Solutions, Consulting, and Outsourcing.",
      },
      jobs: {
        title: "Jobs",
        subtitle: "Do you have what it takes to be Dceeder? Don't wait!",
        description: "We are always looking for new talents and great people passionate about technology. We believe in a rewarding and fulfilling work environment and always looking for ambitious new talents.",
        details: {
          button: "details →",
        },
        openApplication: "If you want to work with us but couldn't find the right job opening, feel free to send your open applications to jobs@dceed.com."
      },
      about: {
        title: "About Us",
        subtitle: "Dceed is a growing IT solutions company headquartered in Istanbul.",
        description: "<p>Dceed is a multinational IT solutions and services company founded by German and Turkish partners who have been working together for a long time. Their work and expertise have been awarded several times with the most prestigious prizes in the marketing industry.</p><p>Building software is our passion. From day one, we help our clients to transform their idea into well-built product experiences. We speak all the modern languages to suit your company stack. Let's find out how we can help your business succeed.</p>"
      },
      clients: {
        title: "Clients",
        subtitle: "From startups to large enterprises we offer best development services.",
        description: "We have worked with startups to banks, fintech to NGOs, e-commerce giants to unicorns. We will be there to find you tailored solutions to your business needs and goals."
      },
      contact: {
        title: "Contact Us",
        subtitle: "We are eager to hear what we can build together.",
        description: "Ask us anything and we’ll get right back at you.",
        details: {
          button: "details →",
        }
      }
    },
    forms: {
      cookie: {
        title: "Cookie Policy",
        text: "We use cookies to enhance the user experience. By clicking 'Accept', you agree to the use of the cookies.",
        accept: "Accept",
        decline: "Decline",
      },
      contact: {
        title: "Let's talk",
        fullname: "Full Name:",
        email: "Work Email:",
        phone: "Phone:",
        company: "Company Name:",
        message: "Message:",
        consent: "I hereby give my consent to the processing of my personal data. ",
        send: "Send",
      },
      application: {
        title: "Apply now:",
        firstname: "First Name:",
        lastname: "Last Name:",
        email: "Email:",
        phone: "Phone:",
        position: "Position:",
        website: "Website:",
        linkedin: "LinkedIn Profile:",
        start: "Your earliest possible starting date (mm/dd/yyyy):",
        salary: "Your salary expectations:",
        resume: "Resume/CV:",
        cover: "Cover Letter:",
        portfolio: "Portfolio:",
        message: "Message:",
        consent: "I hereby give my consent to the processing of my personal data. The consent is granted to Dceed Bilişim Teknolojileri A.Ş. only and is not transferable.",
        submit: "Submit",
        required: "* are required fields. "
      }
    },
    page: {
      index: {
        clients: {
          feedback: {
            text: "They were absolutely excellent to work with.",
            client: "Veg'd" 
          }
        }
      },
      jobs: {
        hero: {
          title: 'Your career starts here',
          subtitle: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et',
        },
        benefits: {
          intro: 'Rewarding work environment, Professionally and Personally',
        },
        open_positions: "Open Positions",
        view_all: 'view all open positions' 
      },
    },
    template: {
      job: {
        tagline: "We are hiring!",
        title: "",
        subtitle: "",
        apply: "Apply now",
        location: "Location",
        experience: "Experience",
        contract: "Contract",
        email: "Email",
        position: "Position",
      }
    }
  },
  'de-de': {
    path: 'de',
    locale: 'de-de',
    siteLanguage: 'de',
    ogLang: 'de_DE',
    defaultTitle: 'Dceed Bilişim Teknolojileri Anonim Şirketi',
    defaultTitleAlt: 'Dceed A.Ş.',
    section: {
      hero: {
        title: "We",
        description: "Wir sind auf die Entwicklung, den Bau, den Einsatz und die Skalierung von erstklassigen Produkten spezialisiert. Lassen Sie uns Ihre Idee gemeinsam umsetzen.",
        button: "Unsere Dienstleistungen sind...",
        loop: {
          design: "Design",
          build: "Build",
          deploy: "Deploy",
          scale: "Scale",
          migrate: "Migrate",
        }
      },
      footer: {
        contact: "Kontakt",
        services: "Services",
        software: "Software-Entwicklung",
        custom: "Kundenspezifische Lösungen",
        business: "Business Lösungen",
        consulting: "Beratung",
        outsourcing: "Outsourcing",
      },
      services: {
        title: "Services",
        subtitle: "Lassen Sie uns mit Ihrer Entwicklungsreise beginnen.",
        description: "Wir sind ein technischer Partner, der Ihnen vom Produktdesign bishin zur kontinuierlichen Lieferung zur Seite steht. Unsere wichtigsten Dienstleistungen umfassen: Softwareentwicklung, kundenspezifische Lösungen, Geschäftslösungen, Beratung und Outsourcing.",
      },
      jobs: {
        title: "Jobs",
        subtitle: "Du möchtest ein Teil von Dceed sein? Warte nicht länger!",
        description: "Wir sind immer auf der Suche nach neuen Talenten und großartigen Menschen mit einer Leidenschaft für Technologie. Wir glauben an eine lohnende und erfüllende Arbeitsumgebung und sind immer auf der Suche nach ehrgeizigen neuen Talenten",
        details: {
          button: "details →",
        },
        openApplication: "Solltest du keine passende Stellenausschreibung finden, du aber gerne mit uns zusammenarbeiten möchtest, dann schicke bitte deine offene Bewerbung an jobs@dceed.com."
      },
      about: {
        title: "Über uns",
        subtitle: "Dceed ist ein wachsendes Unternehmen für IT-Lösungen mit Hauptsitz in Istanbul.",
        description: "<p>Dceed ist ein multinationales Unternehmen für IT-Lösungen und Dienstleistungen, das von deutsch-türkischen Partnern gegründet wurde, die bereits seit langem zusammenarbeiten. Ihre Arbeit und Expertise wurde mehrfach mit den renommiertesten Preisen der Marketingbranche ausgezeichnet.</p><p>Software zu entwickeln ist unsere Leidenschaft. Vom ersten Tag an helfen wir unseren Kunden, ihre Idee in ein erfolgreiches Produkt umzusetzen. Wir sprechen alle modernen Sprachen, die zu Ihrem Unternehmen passen. Lassen Sie uns herausfinden, wie wir Ihnen zum Erfolg verhelfen.</p>"
      },
      clients: {
        title: "Kunden",
        subtitle: "Vom Start-Up bis zum Großunternehmen bieten wir beste Entwicklungsdienstleistungen",
        description: "Wir arbeiten mit Start-ups, Banken, Fintechs, NGOs, E-Commerce-Giganten bishin zu Einhörnern. Wir gestalten maßgeschneiderte Lösungen für Ihre geschäftlichen Bedürfnisse und Ziele."
      },
      contact: {
        title: "Kontaktieren Sie uns",
        subtitle: "Wir sind gespannt auf Ihr Projekt und freuen uns auf eine künftige Zusammenarbeit",
        description: "Fragen Sie uns einfach - wir werden uns sofort bei Ihnen melden",
        details: {
          button: "details →",
        }
      }
    },
    forms: {
      cookie: {
        title: "Cookie-Richtlinie",
        text: "Wir verwenden Cookies, um die Benutzererfahrung zu verbessern. Indem Sie auf 'Akzeptieren' klicken, erklären Sie sich mit der Verwendung der Cookies einverstanden.",
        accept: "Akzeptieren",
        decline: "Ablehnen",
      },
      contact: {
        title: "Lass Sie uns hören",
        fullname: "Name:",
        email: "E-Mail:",
        phone: "Telefon:",
        company: "Firmenname:",
        message: "Nachricht:",
        consent: "Hiermit gebe ich meine Zustimmung zur Verarbeitung meiner persönlichen Daten.",
        send: "Senden",
      },
      application: {
        title: "Jetzt bewerben",
        firstname: "Vorname:",
        lastname: "Nachname:",
        email: "E-Mail:",
        phone: "Telefon:",
        position: "Jobtitel:",
        website: "Website:",
        linkedin: "LinkedIn Profil:",
        start: "Ihr frühestmöglicher Starttermin (mm/tt/jjjjj):",
        salary: "Ihre Gehaltsvorstellungen:",
        resume: "Zusammenfassung/CV:",
        cover: "Cover Letter:",
        portfolio: "Portfolio:",
        message: "Nachricht:",
        consent: "Ich gebe hiermit meine Zustimmung zur Verarbeitung meiner persönlichen Daten. Die Zustimmung wird nur Dceed Bilişim Teknolojileri A.Ş. erteilt und ist nicht übertragbar.",
        submit: "Einreichen",
        required: "* sind Pflichtfelder. "
      }
    },
    page: {
      index: {
        clients: {
          feedback: {
            text: "Die Zusammenarbeit war absolut hervorragend.",
            client: "Veg'd"
          }
        }
      },
      jobs: {
        benefits: {
          intro: 'Angenehmes Arbeitsumfeld mit persönlicher und beruflicher Weiterbildungsmöglichkeit',
        },
        open_positions: "Offene Stellen",
        view_all: 'alle Positionen anzeigen' 
      },
    },
    template: {
      job: {
        tagline: "Wir stellen ein!",
        title: "",
        subtitle: "",
        apply: "Jetzt bewerben",
        location: "Standort",
        experience: "Erfahrung",
        contract: "Vertrag",
        email: "E-Mail",
        position: "Jobtitel",
      }
    }
  },
  'tr': {
    path: 'tr',
    locale: 'tr',
    siteLanguage: 'tr',
    ogLang: 'tr',
    defaultTitle: 'Dceed Bilişim Teknolojileri Anonim Şirketi',
    defaultTitleAlt: 'Dceed A.Ş.',
    section: {
      hero: {
        title: "Size özel",
        description: "Yüksek standartlarda ürün tasarımı, kodlama, ve ölçeklendirme hizmetlerimizle iş fikirlerinizi sizin için hayata geçirelim.",
        button: "Yetkin olduğumuz alanlar",
        loop: {
          design: "Tasarım",
          build: "Kodlama",
          deploy: "Devreye Alma",
          scale: "Ölçeklendirme",
          migrate: "Kurulum",
        }
      },
      footer: {
        contact: "İletişim",
        services: "Hizmetler",
        software: "Yazılım Geliştirme",
        custom: "Özel Çözümler",
        business: "İş Çözümleri",
        consulting: "Danışmanlık",
        outsourcing: "Kayak Sunumu",
      },
      services: {
        title: "Hizmetler",
        subtitle: "Dönüşüm yolculuğunuzu beraber başlatalım",
        description: "Ürün tasarımından, uygulama ve kuruluma kadar size yardımcı olan bir teknoloji ortağıyız. Yetkinliklerimiz: Yazılım Geliştirme, Özel Çözümler, İş Çözümleri, Danışmanlık, Kaynak Sunumu ve e-ticaret",
      },
      jobs: {
        title: "Kariyer",
        subtitle: "Dceeder olmak için gerekenlere sahip misin? Daha fazla bekleme!",
        description: "Her zaman yeni yetenekler ve teknolojiye tutkulu harika insanlar arıyoruz. Ödüllendiren ve yol gösteren iş ortamına inanıyor ve iddialı yeni yeteneklere fırsat tanıyoruz.",
        details: {
          button: "detaylar →",
        },
        openApplication: "Bizimle çalışmak istiyorsan ancak doğru iş ilanını bulamadıysan, açık başvurularını jobs@dceed.com adresine gönderebilirsin."
      },
      about: {
        title: "Hakkımızda",
        subtitle: "Merkezi İstanbul'da bulunan Dceed, her gün büyüyen bir IT çözümleri şirketidir.",
        description: "<p>Dceed, yıllara dayanan birlikte çalışma tecrübelerini aynı platforma taşıyan Alman ve Türk ortaklar tarafından kuruldu. Çalışmaları ve uzmanlıkları pazarlama endüstrisindeki en prestikli ödüllere layık bulundu. Bu çok uluslu şirket sizler için BT çözümleri ve hizmetleri sunuyor.</p><p>Yazılım tutkusuyla yola çıkan Dceed, iş birliğinizin ilk gününden itibaren fikirlerinizi kusursuz ürünlere dönüştürmeye yardımcı olmaktadır. Şirketinizin projelerine uyan tüm modern dillerde destek sunarak, başarınızı şansa bırakmıyoruz.</p>"
      },
      clients: {
        title: "Müşteriler",
        subtitle: "Startup'lardan büyük işletmelere kadar en iyi yazılım geliştirme projeleri...",
        description: "Startup'lardan bankalara, fintech'lerden STK'lara, e-ticaret devlerinden Unicorn'lara kadar birçok farklı şirketle çalıştık. İş ihtiyaçlarına ve hedeflerine özel çözümler."
      },
      contact: {
        title: "İletişim",
        subtitle: "Projenizin detaylarını duymak için can atıyoruz.",
        description: "Bize sorun, hemen size geri dönelim.",
        details: {
          button: "detayları göster →",
        }
      }
    },
    forms: {
      cookie: {
        title: "Çerez Politikası",
        text: "Kullanıcı deneyimini geliştirmek için çerezler kullanıyoruz. 'Kabul Et'i tıklayarak çerezlerin kullanılmasını kabul edebilirsiniz.",
        accept: "Kabul Et",
        decline: "Reddet",
      },
      contact: {
        title: "Tanışalım",
        fullname: "İsim:",
        email: "İş Emaili:",
        phone: "Telefon:",
        company: "Şirket:",
        message: "Mesaj:",
        consent: "Kişisel verilerimin işlenmesine izin veriyorum.",
        send: "Gönder",
      },
      application: {
        title: "Hemen başvur:",
        firstname: "İsim:",
        lastname: "Soyisim:",
        email: "Email:",
        phone: "Telefon:",
        position: "Pozisyon:",
        website: "Website:",
        linkedin: "LinkedIn profili:",
        start: "Mümkün olan en erken başlangıç tarihin (aa/gg/yyyy):",
        salary: "Maaş beklentilerin:",
        resume: "Özgeçmiş/CV:",
        cover: "Ön yazı:",
        portfolio: "Portfolyo:",
        message: "Mesaj:",
        consent: "Kişisel verilerimin işlenmesine izin veriyorum. Onay yalnızca Dceed Bilişim Teknolojileri A.Ş.'ye verilmiştir ve devredilemez.",
        submit: "Gönder",
        required: "* gerekli alanlar. "
      }
    },
    page: {
      index: {
        clients: {
          feedback: {
            text: "Birlikte çalışmak kesinlikle harikaydı.",
            client: "Veg'd" 
          }
        }
      },
      jobs: {
        hero: {
          title: 'Kariyerin burada başlıyor',
          subtitle: 'Bizler birer problem çözücüyüz, tutkumuz karmaşık problemlere çözüm bulmak. Dünyanın her yerinden müşterilere birinci sınıf ürünler ve hizmetler yaratma misyonumuzda bize katıl.',
        },
        benefits: {
          intro: 'Mesleki ve kişisel olarak ödüllendiren çalışma ortamı.',
        },
        open_positions: "Açık Pozisyonlar",
        view_all: 'tüm ilanları gör' 
      },
    },
    template: {
      job: {
        tagline: "İş arkadaşı arıyoruz!",
        title: "",
        subtitle: "",
        apply: "Hemen başvur",
        location: "Konum",
        experience: "Tecrübe",
        contract: "Kontrat",
        email: "Email",
        position: "Pozisyon",
      }
    }
  },
}