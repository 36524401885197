const theme = {
  colors: {
    primary: '#3992d1',
    bg: '#fff',
    bgGrey: '#f5f5f5',
    black: '#000',
    greyLight: '#f5f5f5',
    greyBlue: '#f0f8ff',
    grey: '#595C62',
    greyDark: '#303643',
    greyDarker: '#1c252b',
  },
  maxWidth: '1200px',
  maxWidthText: '720px',
  breakpoints: {
    xs: '340px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
}

export default theme
